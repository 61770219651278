import logo from './logo.png';
import hero from './hero-image.png';
import './App.css';


function App() {
  return (
    <div className="font-rajdhani text-white bg-[#161617] min-h-screen flex flex-col justify-center items-center text-center">
      {/* LOGO */}
      <img src={logo} alt='logo' className='w-48 mx-auto mb-20' />
      {/* HERO */}
      <img src={hero} alt='hero' className='w-96 mx-auto mb-20' />
      {/* NAVBAR */}
      <p className='text-2xl text-white/10 mb-2'>Links mientras tanto:</p>
      <div className='flex space-x-6 mx-auto'>
        <a href='https://eumovement.uteach.io/' className='hover:underline transition-all'>Cursos</a>
        <a href='https://www.instagram.com/eumovement/' className='hover:underline transition-all'>Instagram</a>
        <a href='https://www.youtube.com/@fisioeumovement/featured' className='hover:underline transition-all'>Youtube</a>
        <a href='https://www.instagram.com/eumovement/' className='hover:underline transition-all'>Contacto</a>
      </div>
    </div>
  );
}

export default App;
